import React, { useState } from 'react';
import { createSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Row } from '@devstart/react-bootstrap';
import { selectSentEmailToRecoveryLoading } from '../redux/selectors';
import { forgotPassword } from '../redux/actions';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { ButtonTypes } from '../components/ui/Button/button-types';
import { Spacer } from '../components/helpers';
import BackButton from '../components/ui/BackButton';

import './forgot-password.css';

type FormValues = {
  email: string;
};

type ForgotPasswordProps = {
  readonly sentEmailToRecoveryLoading: boolean;
  readonly forgotPassword: (formValues: FormValues) => void;
};

const defaultValues = {
  email: ''
};

const mapStateToProps = createSelector(
  selectSentEmailToRecoveryLoading,
  (sentEmailToRecoveryLoading: boolean) => ({
    sentEmailToRecoveryLoading
  })
);

const mapDispatchToProps = {
  forgotPassword
};

function ForgotPassword(props: ForgotPasswordProps) {
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);
  const [validationForm, setValidationForm] =
    useState<FormValues>(defaultValues);

  const { sentEmailToRecoveryLoading } = props;

  const { t } = useTranslation();

  function handleEmailChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, email: '' });
    return setFormValues({ ...formValues, email: value });
  }

  function isEmptyField(obj: FormValues) {
    if (obj.email === '') {
      setValidationForm({
        ...validationForm,
        email: 'Campo está vazio, favor verificar.'
      });
      return true;
    } else {
      return false;
    }
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const { forgotPassword } = props;
    const isEmpty = isEmptyField(formValues);
    if (!isEmpty) {
      forgotPassword(formValues);
    }
  }

  return (
    <>
      <Helmet title={`${t('forgot-password.title')} | DEVstart`} />
      <Grid>
        <Spacer size='small' />
        <Row>
          <BackButton prevUrl='/' />
        </Row>
        <Spacer size='medium' />
        <div className='forgot-password-container'>
          <div className='form-forgot-password-container'>
            <p id='forgot-password-title'>{t('forgot-password.title')}</p>
            <Spacer size='small' />
            <p id='forgot-password-subtitle'>{t('forgot-password.subtitle')}</p>
            <Spacer size='medium' />
            <form onSubmit={handleSubmit}>
              <Input
                name='email'
                type='email'
                placeholder='Email'
                value={formValues.email}
                onChange={handleEmailChange}
                hasError={!!validationForm.email}
                messageError={validationForm.email}
              />
              <Spacer size='medium' />
              <div className='form-btn'>
                <Button
                  type='submit'
                  buttonType={ButtonTypes.Primary}
                  style={{ height: '48px', width: '100%' }}
                  isLoading={sentEmailToRecoveryLoading}
                >
                  {t('forgot-password.buttons.send-email')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Grid>
    </>
  );
}

ForgotPassword.displayName = 'ForgotPasswordPage';

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
